import React, {useState, useEffect} from 'react';
import {CButton} from "@coreui/react";
import api from "../../api";
import {getEntity} from "../../reducers/eventReducer";

const EventButton = ({event, type, handleVideoUpdate}) => {
    const [updating, setUpdating] = useState(false);
    const [show, setShow] = useState(false);

    useEffect(() => {

        setShow(false);

        if (type === 'open' && event.streamStatus === 'closed') {
            setShow(true);
        }

        if (type === 'closed' && (event.streamStatus === 'open' || event.streamStatus === 'vod' || event.streamStatus === 'live_finished') && event.streamStatus !== 'live') {
            setShow(true);
        }

        if (type === 'online' && (event.streamStatus === 'open' || event.streamStatus === 'live_interrupted')) {
            setShow(true);
        }

        if (type === 'offline' && event.streamStatus === 'live') {
            setShow(true);
        }

        if (type === 'finished' && (event.streamStatus === 'live' || event.streamStatus === 'live_interrupted')) {
            setShow(true);
        }

        if (type === 'on_demand' && event.streamEnableVod && event.streamStatus === 'live_finished') {
            setShow(true);
        }
    }, [event]);

    const callEventEndpoint = (callType) => {
        setUpdating(true);

        api.instance.post(`/admin/events/${event.id}/status/${callType}`)
            .then((result) => {
                setUpdating(false);
                handleVideoUpdate(result.data);
            }).catch((err) => {
                setUpdating(false);
            });
    };

    const handleClick = () => {
        switch (type) {
            case 'open':
                callEventEndpoint('open');
                break;
            case 'closed':
                callEventEndpoint('closed');
                break;
            case 'online':
                callEventEndpoint('start');
                break;
            case 'offline':
                callEventEndpoint('interruption');
                break;
            case 'finished':
                callEventEndpoint('finished');
                break;
            case 'on_demand':
                callEventEndpoint('vod');
                break;
            default:
                break;
        }
    };

    const getButtonText = () => {
        if (updating) {
            return 'Updating';
        } else {
            switch (type) {
                case 'open':
                    return 'Open Event';
                case 'closed':
                    return 'Close Event';
                case 'online':
                    return 'Put Online';
                case 'offline':
                    return 'Take Offline';
                case 'finished':
                    return 'Stream Complete';
                case 'on_demand':
                    return 'Available On Demand';
                default:
                    break;
            }
        }
    };

    const getButtonColour = () => {
        switch (type) {
            case 'open':
                return 'primary';
            case 'closed':
                return 'primary';
            case 'online':
                return 'danger';
            case 'offline':
                return 'danger';
            case 'finished':
                return 'success';
            case 'on_demand':
                return 'success';
        }
    };

    return (
        <>
            {show && (
                <CButton
                    className="mr-2 mr-sm-3"
                    onClick={handleClick}
                    color={getButtonColour()}
                    disabled={false}>
                    {getButtonText()}
                </CButton>
            )}
        </>
    );
};

export default EventButton;
