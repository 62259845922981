import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import history from '../../history';
import {createEntity, updateEntity, getEntity, reset} from '../../reducers/eventReducer';
import dayjs from "dayjs";
import {Controller, useFieldArray, useForm} from "react-hook-form";
import slugify from "slugify";
import {
    CFormGroup,
    CFormText,
    CInputFile,
    CLabel,
    CNav,
    CNavItem,
    CNavLink,
    CTabContent,
    CTabPane,
    CTabs
} from "@coreui/react";
import TextEditor from "../../modules/TextEditor";
import Dropzone from "react-dropzone";
import {contextAwareUrl, formatDateTimeForInput} from "../../helpers";

const EventUpdateForm = ({id}) => {
    const dispatch = useDispatch();

    const currentEnvironment = useSelector(state => state.context.currentEnvironment);
    const event = useSelector(state => state.event.entity);
    const loading = useSelector(state => state.event.loading);
    const updating = useSelector(state => state.event.updating);
    const updateSuccess = useSelector(state => state.event.updateSuccess);
    const errorMessage = useSelector(state => state.event.errorMessage);
    const [isNew] = useState(!id);

    // initial load
    useEffect(() => {
        if (isNew) {
            dispatch(reset());
        } else {
            dispatch(getEntity(id));
        }
    }, []);

    // // if event changes reload form
    useEffect(() => {
        form.reset({
            ...event,
            start: (formatDateTimeForInput(event.start))
        });
    }, [event]);

    // if successful save redirect back to events
    useEffect(() => {
        if (updateSuccess) {
            if (isNew) {
                history.push(contextAwareUrl('/events'));
            } else {
                history.push(contextAwareUrl('/events/' + id));
            }
        }
    }, [updateSuccess]);

    const form = useForm({
        defaultValues: isNew ? {} : event
    });

    const defaultPerson = {
        "name": "",
        "title": ""
    };

    const defaultProgramme = {
        "length": "",
        "arranger": "",
        "composer": "",
        "title": ""
    };

    const people = useFieldArray({
        control: form.control,
        name: "custom.people"
    });

    const programme = useFieldArray({
        control: form.control,
        name: "custom.programme"
    });

    const handleTitleChange = e => {
        e.persist();
        form.setValue('slug', slugify(e.target.value, { lower: true, strict: true }));
    };

    const showFiles = (acceptedFiles) => {
        const results = document.querySelector('.upload-results');
        results.innerHTML = "";
        acceptedFiles.map(file => (
            results.insertAdjacentHTML('beforeend', `<li>${file.name}, ${file.type}</li>`)
        ));
    };

    const saveEntity = (formData) => {
        const dateTime = (dayjs(`${formData.start}`,'YYYY-MM-DDTHH:mm').format('YYYY-MM-DDTHH:mm:ss[Z]'));
        const eventData = {
            "environmentId": currentEnvironment.id,
            "title": formData.title,
            "start": dateTime,
            "introduction": formData.introduction,
            "description": formData.description,
            "metaDescription": formData.metaDescription,
            "slug": formData.slug,
            "streamFileUrl": formData.streamFileUrl,
            "streamAutoStart": formData.streamAutoStart,
            "streamEnableVod": formData.streamEnableVod,
            "streamVodDuration": formData.streamVodDuration,
            "custom": {
                ...event.custom,
                people: formData.custom.people,
                programme: formData.custom.programme,
                trailerUrl: formData.custom.trailerUrl
            }
        };

        if (isNew) {
            dispatch(createEntity(eventData));
        } else {
            eventData.id = id;
            dispatch(updateEntity(eventData));
        }
    };

    return (
        <>
            {loading ? (
                <div>Loading...</div>
            ) : (
                <form onSubmit={form.handleSubmit(saveEntity)}>
                    <CTabs activeTab="setup">
                        <CNav variant="tabs">
                            <CNavItem>
                                <CNavLink data-tab="setup">
                                    Setup
                                </CNavLink>
                            </CNavItem>
                            <CNavItem>
                                <CNavLink data-tab="assets">
                                    Assets
                                </CNavLink>
                            </CNavItem>
                            <CNavItem>
                                <CNavLink data-tab="meta">
                                    Meta Data
                                </CNavLink>
                            </CNavItem>
                            <CNavItem>
                                <CNavLink data-tab="information">
                                    Information
                                </CNavLink>
                            </CNavItem>
                            <CNavItem>
                                <CNavLink data-tab="streaming">
                                    Stream Settings
                                </CNavLink>
                            </CNavItem>
                        </CNav>

                        <CTabContent>
                            <CTabPane data-tab="setup">
                                <div className={'card shadow-none'}>
                                    <div className={'card-body'}>
                                        <CFormGroup>
                                            <CLabel htmlFor="title">Title</CLabel>
                                            <input type="text" className="form-control" name="title" ref={form.register} onChange={handleTitleChange} />
                                        </CFormGroup>

                                        <CFormGroup>
                                            <CLabel htmlFor="slug">Slug</CLabel>
                                            <input type="text" className="form-control" name="slug" ref={form.register} />
                                        </CFormGroup>

                                        <CFormGroup>
                                            <CLabel htmlFor="introduction">Introduction</CLabel>

                                            <Controller
                                                control={form.control}
                                                name="introduction"
                                                render={({ onChange, value }) => (
                                                    <TextEditor
                                                        onChange={(introduction) => onChange(introduction)}
                                                        value={value || ''}
                                                    />
                                                )}
                                            />
                                        </CFormGroup>

                                        <CFormGroup>
                                            <CLabel htmlFor="description">Description</CLabel>

                                            <Controller
                                                control={form.control}
                                                name="description"
                                                render={({ onChange, value }) => (
                                                    <TextEditor
                                                        onChange={(description) => onChange(description)}
                                                        value={value || ''}
                                                    />
                                                )}
                                            />
                                        </CFormGroup>

                                        <CFormGroup>
                                            <div className={"row"}>
                                                <div className={`col-3`}>
                                                    <label htmlFor="event-date">Start</label>
                                                    {/* this will need to be updated for something with better browser compatibility */}
                                                    <input type="datetime-local" className="form-control" name="start" ref={form.register} />
                                                </div>
                                            </div>
                                        </CFormGroup>
                                    </div>
                                </div>
                            </CTabPane>
                        </CTabContent>

                        <CTabContent>
                            <CTabPane data-tab="assets">
                                <div className={'card shadow-none'}>
                                    <div className={'card-body'}>
                                        <CFormGroup>
                                            <CLabel htmlFor="hero">Hero Image</CLabel>
                                            <CInputFile id="hero"/>
                                            <CFormText className="help-block">Upload a hero image for this event</CFormText>

                                            {event.custom && event.custom.hero && (
                                                <div className="mb-3">
                                                    <img src={event.custom.hero} className="img-thumbnail w-25" />
                                                </div>
                                            )}
                                        </CFormGroup>

                                        <CFormGroup>
                                            <CLabel htmlFor="thumbnail">Thumbnail Image</CLabel>
                                            <CInputFile id="thumbnail"/>
                                            <CFormText className="help-block">Upload a preview image for this event</CFormText>

                                            {event.custom && event.custom.thumbnail && (
                                                <div className="mb-3">
                                                    <img src={event.custom.thumbnail} className="img-thumbnail w-25" />
                                                </div>
                                            )}
                                        </CFormGroup>

                                        <CFormGroup>
                                            <CLabel htmlFor="custom.trailerUrl">Trailer Url</CLabel>
                                            <input type="text" className="form-control" name="custom.trailerUrl" ref={form.register} />
                                        </CFormGroup>
                                    </div>
                                </div>
                            </CTabPane>
                        </CTabContent>

                        <CTabContent>
                            <CTabPane data-tab="meta">
                                <div className={'card shadow-none'}>
                                    <div className={'card-body'}>
                                        <CFormGroup>
                                            <CLabel htmlFor="metaDescription">Description</CLabel>
                                            <textarea className="form-control" name="metaDescription" ref={form.register} />
                                        </CFormGroup>

                                        <CFormGroup>
                                            <CLabel htmlFor="hero">Twitter Preview</CLabel>
                                            <CInputFile id="hero"/>
                                            <CFormText className="help-block">Upload a preview image for Twitter posts</CFormText>

                                            {event.custom && event.custom.twitter && (
                                                <div className="mb-3">
                                                    <img src={event.custom.twitter} className="img-thumbnail w-25" />
                                                </div>
                                            )}
                                        </CFormGroup>

                                        <CFormGroup>
                                            <CLabel htmlFor="hero">Facebook Preview</CLabel>
                                            <CInputFile id="hero"/>
                                            <CFormText className="help-block">Upload a preview image for Facebook posts</CFormText>
                                            {event.custom && event.custom.facebook && (
                                                <div className="mb-3">
                                                    <img src={event.custom.facebook} className="img-thumbnail w-25" />
                                                </div>
                                            )}
                                        </CFormGroup>
                                    </div>
                                </div>
                            </CTabPane>
                        </CTabContent>

                        <CTabContent>
                            <CTabPane data-tab="information">
                                <div className={'card shadow-none'}>
                                    <div className={'card-body'}>
                                        <div>
                                            <label>People</label>
                                        </div>

                                        <CFormGroup>
                                            {people.fields.map((item, index) => (
                                                <div className={"row"} key={item.id}>
                                                    <div className={`col-2`}>
                                                        <CLabel htmlFor={`custom.people[${index}].name`}>Name</CLabel>
                                                        <input type="text" className="form-control" name={`custom.people[${index}].name`} defaultValue={item.name} ref={form.register()} />
                                                    </div>

                                                    <div className={`col-2`}>
                                                        <CLabel htmlFor={`custom.people[${index}].title`}>Title</CLabel>
                                                        <input type="text" className="form-control" name={`custom.people[${index}].title`} defaultValue={item.title} ref={form.register()} />
                                                    </div>

                                                    <div className={`col-1`}>
                                                        <CLabel>&nbsp;</CLabel>

                                                        {(index !== 0) && (
                                                            <button type="button" className="btn btn-block btn-success" onClick={() => people.swap(index, index - 1)}>
                                                                <span className="cil-arrow-top btn-icon"></span>
                                                            </button>
                                                        )}

                                                        {(index !== people.fields.length - 1) && (
                                                            <button type="button" className="btn btn-block btn-success" onClick={() => people.swap(index, index + 1)}>
                                                                <span className="cil-arrow-bottom btn-icon"></span>
                                                            </button>
                                                        )}

                                                        <button type="button" className="btn btn-block btn-danger" onClick={() => people.remove(index)}>
                                                            <span className="cil-trash btn-icon"></span>
                                                        </button>
                                                    </div>
                                                </div>
                                            ))}
                                        </CFormGroup>

                                        <button type="button" className="btn btn-success" onClick={() => people.append(defaultPerson)}>Add</button>
                                    </div>

                                    <div className={'card-body'}>
                                        <div>
                                            <label>Programme</label>
                                        </div>

                                        <CFormGroup>
                                            {programme.fields.map((item, index) => (
                                                <div className={"row"} key={item.id}>
                                                    <div className={`col-2`}>
                                                        <CLabel htmlFor={`custom.programme[${index}].title`}>Title</CLabel>
                                                        <input type="text" className="form-control" name={`custom.programme[${index}].title`} defaultValue={item.title} ref={form.register()} />
                                                    </div>

                                                    <div className={`col-2`}>
                                                        <CLabel htmlFor={`custom.programme[${index}].arranger`}>Arranger</CLabel>
                                                        <input type="text" className="form-control" name={`custom.programme[${index}].arranger`} defaultValue={item.arranger} ref={form.register()} />
                                                    </div>

                                                    <div className={`col-2`}>
                                                        <CLabel htmlFor={`custom.programme[${index}].composer`}>Composer</CLabel>
                                                        <input type="text" className="form-control" name={`custom.programme[${index}].composer`} defaultValue={item.composer} ref={form.register()} />
                                                    </div>

                                                    <div className={`col-2`}>
                                                        <CLabel htmlFor={`custom.programme[${index}].length`}>Length</CLabel>
                                                        <input type="text" className="form-control" name={`custom.programme[${index}].length`} defaultValue={item.length} ref={form.register()} />
                                                    </div>

                                                    <div className={`col-1`}>
                                                        <CLabel>&nbsp;</CLabel>

                                                        {(index !== 0) && (
                                                            <button type="button" className="btn btn-block btn-success" onClick={() => programme.swap(index, index - 1)}>
                                                                <span className="cil-arrow-top btn-icon"></span>
                                                            </button>
                                                        )}

                                                        {(index !== programme.fields.length - 1) && (
                                                            <button type="button" className="btn btn-block btn-success" onClick={() => programme.swap(index, index + 1)}>
                                                                <span className="cil-arrow-bottom btn-icon"></span>
                                                            </button>
                                                        )}

                                                        <button type="button" className="btn btn-block btn-danger" onClick={() => programme.remove(index)}>
                                                            <span className="cil-trash btn-icon"></span>
                                                        </button>
                                                    </div>
                                                </div>
                                            ))}
                                        </CFormGroup>

                                        <button type="button" className="btn btn-success" onClick={() => programme.append(defaultProgramme)}>Add</button>
                                    </div>
                                </div>
                            </CTabPane>
                        </CTabContent>

                        <CTabContent>
                            <CTabPane data-tab="streaming">
                                <div className={'card shadow-none'}>
                                    <div className={'card-body'}>
                                        <p className="help-block">Pre-uploaded streaming can be set to start
                                            automatically, if this is not selected you must manually start the stream
                                            for the event.</p>

                                        <CFormGroup className="row">
                                            <CLabel className={`col-md-2`} htmlFor="streamAutoStart">Automatically start</CLabel>

                                            <input type="checkbox" className="form-check-input col-md-1" name="streamAutoStart" ref={form.register} />
                                        </CFormGroup>

                                        <p className="help-block">Videos can either be uploaded directly (up to a
                                            maximum of 5gb) or a public url containing the video can be used to pull the
                                            video from e.g. Dropbox.</p>

                                        <CFormGroup>
                                            <div className={"row"}>
                                                <div className={`col-5`}>
                                                    <CLabel htmlFor="streamFileUrl">File Url</CLabel>

                                                    <input type="text" className="form-control" name={'streamFileUrl'} ref={form.register} />
                                                </div>

                                                <div className={`col-1`}>
                                                    <div style={{textAlign: "center"}}>or</div>
                                                </div>

                                                <div className={`col-6`}>
                                                    <CLabel htmlFor="event-video">Upload</CLabel>
                                                    <Dropzone id="event-video"
                                                              onDrop={acceptedFiles => showFiles(acceptedFiles)}>
                                                        {({getRootProps, getInputProps, isDragActive}) => (
                                                            <section>
                                                                <div className="dropzone" {...getRootProps()}>
                                                                    <input {...getInputProps()} multiple={false}
                                                                           id="event-file"/>
                                                                    {
                                                                        isDragActive ?
                                                                            <p>Drop the file here ...</p> :
                                                                            <p>Drag a file here, or click to browse
                                                                                files</p>
                                                                    }

                                                                </div>
                                                            </section>
                                                        )}
                                                    </Dropzone>
                                                    <ul className={'upload-results pt-3'}></ul>
                                                </div>
                                            </div>
                                        </CFormGroup>

                                        <p className="help-block">Playback can be enabled for on demand watching after
                                            an event has finished streaming.</p>

                                        <CFormGroup className="row">
                                            <CLabel className={`col-md-2`} htmlFor="streamEnableVod">Enable playback</CLabel>

                                            <input type="checkbox" className="form-check-input col-md-1" name="streamEnableVod" ref={form.register} />
                                        </CFormGroup>
                                    </div>
                                </div>
                            </CTabPane>
                        </CTabContent>
                    </CTabs>

                    <div className="row mb-4">
                        <div className={`col-md-2`}>
                            <button type="submit" className="btn btn-primary d-flex align-items-center"
                                    disabled={updating}>
                                <span className="cil-check-alt btn-icon mr-2"></span>
                                Save event
                            </button>
                        </div>
                    </div>

                    {errorMessage && (
                        <div className="alert alert-danger">{errorMessage.message}</div>
                    )}
                </form>
            )}
        </>
    )
};

export default EventUpdateForm;
