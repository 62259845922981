import React, {useState} from "react";
import AttendingList from "../../components/users/AttendingList";

import {
    CDataTable,
    CBadge,
    CButton,
    CCollapse,
    CCardBody,
} from '@coreui/react'
import {encodeBase64} from "../../helpers";
import {Link} from "react-router-dom";

const UserList = ({usersData, userList, userListTitle}) => {

      const [details, setDetails] = useState([]);
      // const [items, setItems] = useState(usersData)

    console.log('usersData: ', usersData);

      const toggleDetails = (index) => {
        const position = details.indexOf(index);
        let newDetails = details.slice();
        if (position !== -1) {
          newDetails.splice(position, 1)
        } else {
          newDetails = [...details, index]
        }
        setDetails(newDetails)
      };

      let fields = [];

      if (userList === 'Administrators') {
        fields.push(
          { key: 'name', _style: { width: '30%'} },
          { key: 'email', _style: { width: '30%'} },
          { key: 'role', _style: { width: '20%'} },
          { key: 'status', _style: { width: '20%'} },
          {
            key: 'show_details',
            label: '',
            _style: { width: '1%' },
            sorter: false,
            filter: false
          }
        )
      } else if (userList === 'Audience') {
        fields.push(
          { key: 'name', _style: { width: '30%'} },
          { key: 'email', _style: { width: '30%'} },
          { key: 'status', _style: { width: '20%'} },
          {
            key: 'manage_link',
            label: '',
            _style: { width: '1%' },
            sorter: false,
            filter: false
          }
        )
      } else if (userList === 'AttendingUsers') {
          fields.push(
              { key: 'name', _style: { width: '40%'} },
              { key: 'email', _style: { width: '40%'} },
              { key: 'attended', _style: { width: '20%'} }
          )
      }
      else if (userList === 'Accounts') {
          fields.push(
              { key: 'name', _style: { width: '30%'} },
              { key: 'email', _style: { width: '30%'} },
              // { key: 'location', _style: { width: '20%'} },
              // { key: 'status', _style: { width: '20%'} },
              {
                  key: 'show_details',
                  label: '',
                  _style: { width: '1%' },
                  sorter: false,
                  filter: false
              }
          )
      }

      //TODO: THIS NEEDS TO COME FROM THE API
      const getBadge = (status)=>{
        switch (status) {
          case 'Active': return 'success'
          case 'Watching now': return 'success'
          case 'Booked': return 'primary'
          case 'Inactive': return 'secondary'
          case 'Pending': return 'warning'
          case 'Banned': return 'danger'
          default: return 'primary'
        }
      }

      return (
        <div className={'card shadow-none'}>
            <div className={'card-body table-responsive'}>
                <h5 className={'card-title'}>{userListTitle}</h5>
                    <CDataTable
                    items={usersData}
                    fields={fields}
                    columnFilter
                    tableFilter
                    itemsPerPageSelect
                    itemsPerPage={10}
                    hover
                    sorter
                    pagination
                    scopedSlots = {{
                    'name':
                        (item) => (
                            <td className="align-middle">
                                <Link to={`/audience/${encodeBase64(item.id)}`}>
                                    {item.name}
                                </Link>
                            </td>
                    ),
                      'status':
                      (item)=>(
                          <td>
                          <CBadge color={getBadge(item.status ? item.status : 'Watching now')}>
                              {item.status ? item.status : 'Watching now'}
                          </CBadge>
                          </td>
                      ),
                      'show_details':
                      (item, index)=>{
                          return (
                          <td className="py-2">
                              <CButton
                              color="primary"
                              shape="square"
                              size="sm"
                              onClick={()=>{toggleDetails(index)}}
                              >
                              {details.includes(index) ? 'Hide' : 'Show'}
                              </CButton>
                          </td>
                          )
                      },
                      'manage_link':
                          (item, index)=>{
                            return (
                                <td className="py-2">
                                    <CButton
                                        color="primary"
                                        shape="rounded"
                                        size="sm"
                                        className="d-flex align-items-center"
                                        to={`/audience/${encodeBase64(item.id)}`}
                                    >
                                      Manage <span className={'cil-cog cil-button ml-2'}></span>
                                    </CButton>
                                </td>
                          )
                      },
                      'attended':
                        (item) => {
                          return (
                            <td className={'text-center'}>
                              <span
                                className={
                                  item.attended ?
                                    'cil-check-alt text-success mx-auto align-middle font-weight-bold'
                                  :
                                    'cil-x text-danger mx-auto align-middle font-weight-bold'
                                  }></span>
                            </td>
                          )
                      },
                      'details':
                          (item, index)=>{
                          return (
                          <CCollapse show={details.includes(index)}>
                              <CCardBody>
                              <h4>
                                  {`${item.name}`}
                              </h4>

                              <p className="text-muted">{item.email}</p>
                              {/*<p className="text-muted">{item.location ? item.location : item.role}</p>*/}

                              {/*<div>*/}
                              {/*  {userList === 'Audience'*/}
                              {/*    ? <AttendingList attending={item.attending} />*/}
                              {/*    : ''*/}
                              {/*  }*/}
                              {/*</div>*/}

                              {
                                  userList === 'Accounts' &&
                                      <CButton to={`account/${item.id}`} size="sm" color="info">
                                          Account Settings
                                      </CButton>

                              }
                              {
                                  userList === 'Audience' &&
                                  <CButton to={`/audience/${encodeBase64(item.id)}`} size="sm" color="info">
                                      User Settings
                                  </CButton>
                              }

                              {/*<CButton size="sm" color="danger" className="ml-1">*/}
                              {/*    Remove user*/}
                              {/*</CButton>*/}
                              </CCardBody>
                          </CCollapse>
                        )
                    }
                  }}
                />
            </div>
        </div>
    )
}

export default UserList;
