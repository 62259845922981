import React, {useEffect} from 'react'
import AudienceAdmin from '../../components/users/AudienceAdmin';
import { Link } from 'react-router-dom';
import {decodeBase64} from "../../helpers";

const AudienceAdminPage = (props) => {

    return (
        <>
            <Link to="/audience" className="btn btn-dark w-fit mb-3 d-flex align-items-center">
                <span className="cil-arrow-left btn-icon mr-2"></span> Back to audience
            </Link>
            <AudienceAdmin id={decodeBase64(props.match.params.id)} />
        </>
    )
};

export default AudienceAdminPage;
