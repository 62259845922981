import dayjs from "dayjs";
import history from "../history";

export function encodeBase64(str) {
    return btoa(str);
}

export function decodeBase64(str) {
    if(str) {
        return atob(str);
    }
}

// TODO: need to sort the subtracting 1, if we dont do this time is out by an hour
export function displayDateTime(dateTime) {
    return dayjs(dateTime).format('H:mm, dddd D MMMM YYYY');
}

export function formatDateTimeForInput(dateTime) {
    return dayjs(dateTime, 'YYYY-MM-DDTHH:mm:ss[Z]').format('YYYY-MM-DDTHH:mm')
}

export function contextAwareUrl(url) {
    console.log('context aware: ', history.location.pathname.split('/').splice(0,5).join().replaceAll(',','/'))
    return `${history.location.pathname.split('/').splice(0,5).join().replaceAll(',','/')}${url}`
}
