import React, {useEffect, useState} from "react";
import VideojsPlayer from "../video/VideojsPlayer";
import SocketIO from "../../api/sockets";

const Player = ({streamPlaybackId, dataStatus}) => {

    //Set up the socket
    const socketIO = SocketIO();
    const socket = socketIO.getSocketInstance();

    const src = `https://stream.mux.com/${streamPlaybackId}.m3u8?timestamp=${new Date().getTime()}`;
    const [dataConnected, setDataConnected] = useState(dataStatus === 'on');

    useEffect(() => {
        socket.emit('admin-subscribe', {
            token: socketIO.getAuthorization()
        });

        socket.on('data-on', () => {
            setDataConnected(true);
        });

        socket.on('data-off', () => {
            setDataConnected(false);
        });

        return function cleanup() {
            socket.emit('admin-unsubscribe', {
                token: socketIO.getAuthorization()
            });
        };

    }, [streamPlaybackId, dataStatus]);

    return (
        <div className="mb-3">
            {
                !dataConnected ?
                    <div style={{height: "209.98px", backgroundColor: "#ebedef"}}>
                        <button className="mt-2 ml-2 btn btn-outline-danger" type="button" disabled>not connected
                        </button>
                    </div>
                    :
                    <VideojsPlayer src={`${src}`}
                                   setVideoPlayerEnded={() => {
                                       console.log('Video player finished');
                                   }}/>

            }
        </div>
    )
};

export default React.memo(Player)
