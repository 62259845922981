import EventButton from "./EventButton";
import React from "react";

const EventButtons = ({event, handleVideoUpdate}) => {
    return (
        <>
            <EventButton type="open" event={event} handleVideoUpdate={handleVideoUpdate}/>
            <EventButton type="online" event={event} handleVideoUpdate={handleVideoUpdate}/>
            <EventButton type="offline" event={event} handleVideoUpdate={handleVideoUpdate}/>
            <EventButton type="finished" event={event} handleVideoUpdate={handleVideoUpdate}/>
            <EventButton type="on_demand" event={event} handleVideoUpdate={handleVideoUpdate}/>
            <EventButton type="closed" event={event} handleVideoUpdate={handleVideoUpdate}/>
        </>
    )
};

export default EventButtons;