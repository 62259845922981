import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {
    CTabs,
    CNav,
    CNavItem,
    CNavLink,
    CTabContent,
    CTabPane,
    CModal,
    CModalHeader,
    CModalBody,
    CModalFooter,
    CButton,
    CWidgetProgress,
} from '@coreui/react'

import {contextAwareUrl, displayDateTime} from '../../helpers'
import Player from './Player';
import {getEntity, deleteEntity} from "../../reducers/eventReducer";
import {getEntities as getEventAttendees, getStats} from "../../reducers/eventAttendeeReducer";
import EventButtons from './EventButtons';
import history from "../../history";
import EventAttendeeList from "./EventAttendeeList";
import {useInterval} from "../../hooks/useInterval";

const EventDetail = ({id}) => {
    const dispatch = useDispatch();

    const event = useSelector(state => state.event.entity);
    const eventLoading = useSelector(state => state.event.loading);
    const updateSuccess = useSelector(state => state.event.updateSuccess);

    const eventAttendees = useSelector(state => state.eventAttendee.entities);
    const eventAttendeesLoading = useSelector(state => state.eventAttendee.loading);

    const eventAttendeeStats = useSelector(state => state.eventAttendee.stats);

    const [deleteModal, setDeleteModal] = useState(false);

    const toggleDeleteModal = ()=>{
        setDeleteModal(!deleteModal);
    };

    const confirmDelete = () => {
        dispatch(deleteEntity(event));
    };

    const handleVideoUpdate = (videoEvent) => {
        console.log('handleVideoUpdate: ', videoEvent);
        dispatch(getEntity(id));
    };

    const getStatusText = () => {
        switch (event.streamStatus) {
            case 'open':
                return 'Open';
            case 'closed':
                return 'Closed';
            case 'live':
                return 'Online';
            case 'live_interrupted':
                return 'Offline';
            case 'live_finished':
                return 'Finished';
            case 'vod':
                return 'On Demand';
        }
    };

    useEffect(() => {
        if (updateSuccess) {
            history.push(contextAwareUrl('/events'));
            setDeleteModal(false);
        }
    }, [updateSuccess]);

    useEffect(() => {
        dispatch(getEntity(id));
    }, []);

    useEffect(() => {
        dispatch(getEventAttendees(id));
        dispatch(getStats(id));
    }, []);

    useInterval(() => {
        dispatch(getStats(id));
    }, 30000);

    return (
        <>
            {(eventAttendeesLoading) ? (
                <div>Loading...</div>
            ) : (
                <>
                    <CModal show={deleteModal} onClose={toggleDeleteModal}>
                        <CModalHeader closeButton>Delete {event.title}</CModalHeader>

                        <CModalBody>
                            Are you sure?
                        </CModalBody>

                        <CModalFooter>
                            <CButton color="danger" onClick={confirmDelete}>Confirm</CButton>{' '}
                            <CButton color="secondary" onClick={toggleDeleteModal}>Cancel</CButton>
                        </CModalFooter>
                    </CModal>

                    <div className="row align-items-center">
                        <div className="col-12 col-sm-8">
                            <h3 className={'card-title m-0'}>{event.id} - {event.title}</h3>
                        </div>
                        <div className="col-12 col-sm-4">
                            <div className={'d-flex justify-content-end mb-3'}>
                                <Link to={contextAwareUrl('/events/' + event.id + '/edit')}
                                      className="btn btn-primary mr-3">
                                    <span className="cil-pencil btn-icon mr-2"></span> Edit
                                </Link>

                                <CButton onClick={toggleDeleteModal} color="danger">
                                    <span className="cil-trash btn-icon mr-2"></span> Delete
                                </CButton>
                            </div>
                        </div>
                    </div>

                    <CTabs activeTab="streaming">
                        <CNav variant="tabs">
                            <CNavItem>
                                <CNavLink data-tab="streaming">
                                    Stream Control
                                </CNavLink>
                            </CNavItem>
                            <CNavItem>
                                <CNavLink data-tab="content">
                                    Content
                                </CNavLink>
                            </CNavItem>
                            <CNavItem>
                                <CNavLink data-tab="audience">
                                    Audience
                                </CNavLink>
                            </CNavItem>
                        </CNav>
                        <CTabContent>
                            <CTabPane data-tab="streaming">
                                <div className={'card shadow-none'}>
                                    <div className={'card-body'}>
                                        <div className="row">
                                            <div className="col-8">
                                                <div className="row mb-3">
                                                    <div className="col-12">
                                                        <h5>RTMP URL</h5>

                                                        <div className="form-control">
                                                            rtmp://global-live.mux.com:5222/app/{event.streamKey}
                                                        </div>

                                                        <p className="mt-3">or</p>

                                                        <div className="form-control">
                                                            rtmps://global-live.mux.com:443/app/{event.streamKey}
                                                        </div>

                                                        {/*<CButton color="success" className="mt-3">Copy stream key</CButton>*/}
                                                    </div>
                                                </div>

                                                <div className="row mb-3">
                                                    <div className="col-12">
                                                        <h5>Embed code</h5>

                                                        <p>goes in the head of page</p>

                                                        <div className="form-control">
                                                            &lt;script type="text/javascript" src="https://scripts.440.io/v1/orchestra.min.js"&gt;&lt;/script&gt;
                                                        </div>

                                                        {/*<CButton color="success" className="mt-3">Copy script code</CButton>*/}

                                                        <p className="mt-3">goes in the body of page</p>

                                                        <div className="form-control" style={{height: "100px"}}>
                                                            &lt;div id="event-{event.id}"&gt;&lt;/div&gt;
                                                            <br />
                                                            &lt;script&gt;
                                                            var conductor = new _440Conductor('41cad05c-6a93-4cd2-aa07-5db7ac3c86a0', '{event.id}');
                                                            &lt;/script&gt;
                                                        </div>

                                                        {/*<CButton color="success" className="mt-3">Copy embed code</CButton>*/}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-4">
                                                <h5 className={'card-title'}>Status: {getStatusText()}</h5>

                                                <div className="row mb-3">
                                                    <div className="col-12">
                                                        <EventButtons event={event} handleVideoUpdate={handleVideoUpdate} />
                                                    </div>
                                                </div>

                                                <Player
                                                    streamPlaybackId={event.streamPlaybackId}
                                                    dataStatus={event.dataStatus} />

                                                <div className="row mt-3">
                                                    <div className="col-6">
                                                        <CWidgetProgress
                                                            color="primary"
                                                            header={eventAttendeeStats?.currentUsers}
                                                            text="Online Viewers"
                                                        />
                                                    </div>

                                                    <div className="col-6">
                                                        <CWidgetProgress
                                                            color="success"
                                                            header={eventAttendeeStats?.totalViewers}
                                                            text="Total Viewers"
                                                        />
                                                    </div>
                                                </div>

                                                {event.streamEnableVod && (
                                                    <>
                                                        <h5 className={'card-title'}>Vod</h5>

                                                        <Player
                                                            src={"https://multiplatform-f.akamaihd.net/i/multi/april11/sintel/sintel-hd_,512x288_450_b,640x360_700_b,768x432_1000_b,1024x576_1400_m,.mp4.csmil/master.m3u8"}/>
                                                    </>
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CTabPane>

                            <CTabPane data-tab="content">
                                <div className={'card shadow-none'}>
                                    <div className={'card-body'}>
                                        <dl className="row">
                                            <dt className="col-sm-2">
                                                Starts
                                            </dt>
                                            <dd className="col-sm-10">
                                                <div className="mb-3">
                                                    {displayDateTime(event.start)}
                                                </div>
                                            </dd>
                                            {event.slug && (
                                                <>
                                                    <dt className="col-sm-2">
                                                        Slug
                                                    </dt>
                                                    <dd className="col-sm-10">
                                                        <div className="mb-3">
                                                            {event.slug}
                                                        </div>
                                                    </dd>
                                                </>
                                            )}
                                            {event.introduction && (
                                                <>
                                                    <dt className="col-sm-2">
                                                        Introduction
                                                    </dt>
                                                    <dd className="col-sm-10">
                                                        <div dangerouslySetInnerHTML={{__html: event.introduction}}/>
                                                    </dd>
                                                </>
                                            )}
                                            {event.description && (
                                                <>
                                                    <dt className="col-sm-2">
                                                        Description
                                                    </dt>
                                                    <dd className="col-sm-10">
                                                        <div dangerouslySetInnerHTML={{__html: event.description}}/>
                                                    </dd>
                                                </>
                                            )}
                                            {event.custom && event.custom.hero && (
                                                <>
                                                    <dt className="col-sm-2">
                                                        Hero
                                                    </dt>
                                                    <dd className="col-sm-10">
                                                        <div className="mb-3">
                                                            <img src={event.custom.hero} className="img-thumbnail w-25" />
                                                        </div>
                                                    </dd>
                                                </>
                                            )}
                                            {event.custom && event.custom.thumbnail && (
                                                <>
                                                    <dt className="col-sm-2">
                                                        Thumbnail
                                                    </dt>
                                                    <dd className="col-sm-10">
                                                        <div className="mb-3">
                                                            <img src={event.custom.thumbnail} className="img-thumbnail w-25" />
                                                        </div>
                                                    </dd>
                                                </>
                                            )}
                                        </dl>
                                    </div>

                                    <div className={'card-header'}>
                                        <strong>Meta</strong>
                                    </div>

                                    <div className={'card-body'}>
                                        <dl className="row">
                                            {event.metaDescription && (
                                                <>
                                                    <dt className="col-sm-2">
                                                        Description
                                                    </dt>
                                                    <dd className="col-sm-10">
                                                        <p>{event.metaDescription}</p>
                                                    </dd>
                                                </>
                                            )}
                                            {event.custom && event.custom.twitter && (
                                                <>
                                                    <dt className="col-sm-2">
                                                        Twitter Preview
                                                    </dt>
                                                    <dd className="col-sm-10">
                                                        <div className="mb-3">
                                                            <img src={event.custom.twitter} className="img-thumbnail w-25" />
                                                        </div>
                                                    </dd>
                                                </>
                                            )}
                                            {event.custom && event.custom.facebook && (
                                                <>
                                                    <dt className="col-sm-2">
                                                        Facebook Preview
                                                    </dt>
                                                    <dd className="col-sm-10">
                                                        <div className="mb-3">
                                                            <img src={event.custom.facebook} className="img-thumbnail w-25" />
                                                        </div>
                                                    </dd>
                                                </>
                                            )}
                                        </dl>
                                    </div>

                                    <div className={'card-header'}>
                                        <strong>Information</strong>
                                    </div>

                                    <div className={'card-body'}>
                                        <dl className="row">
                                            {(event.custom && event.custom.people && event.custom.people.length > 0) && (
                                                <>
                                                    <dt className="col-sm-2">
                                                        People
                                                    </dt>
                                                    <dd className="col-sm-10">
                                                        <div className="mb-3">
                                                            {event.custom.people.map((person, index) => {
                                                                let itemText = `${person.name} - ${person.title}`;

                                                                return (
                                                                    <div key={`person-${index}`}>
                                                                        {itemText}
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </dd>
                                                </>
                                            )}

                                            {(event.custom && event.custom.programme && event.custom.programme.length > 0) && (
                                                <>
                                                    <dt className="col-sm-2">
                                                        Programme
                                                    </dt>
                                                    <dd className="col-sm-10">
                                                        <div className="mb-3">
                                                            {event.custom.programme.map((item, index) => {
                                                                let itemText = `${item.composer} - ${item.title}`;

                                                                if (item.arranger) {
                                                                    itemText = itemText + `, ${item.arranger}`;
                                                                }

                                                                itemText = itemText + `[${item.length}]`;

                                                                return (
                                                                    <div key={`programme-item-${index}`}>
                                                                        {itemText}
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </dd>
                                                </>
                                            )}
                                        </dl>
                                    </div>
                                </div>
                            </CTabPane>

                            <CTabPane data-tab="audience">
                                {eventAttendees && (
                                    <EventAttendeeList attendeeData={eventAttendees.attendees} />
                                )}
                            </CTabPane>
                        </CTabContent>
                    </CTabs>
                </>
            )}
        </>
    )
}

export default EventDetail;
