import api from "../api";

import {REQUEST, SUCCESS, FAILURE} from './action-type.util';

export const ACTION_TYPES = {
    FETCH_EVENT_ATTENDEE_LIST: 'event/FETCH_EVENT_ATTENDEE_LIST',
    FETCH_EVENT_STATS: 'event/FETCH_EVENT_STATS',
    RESET: 'event/RESET',
};

const initialState = {
    loading: false,
    errorMessage: null,
    entities: [],
    stats: null,
    updating: false,
    updateSuccess: false,
};

// Reducer
export default (state = initialState, action) => {

    console.log(action);

    switch (action.type) {
        case REQUEST(ACTION_TYPES.FETCH_EVENT_ATTENDEE_LIST):
            return {
                ...state,
                errorMessage: null,
                updateSuccess: false,
                loading: true,
            };
        case FAILURE(ACTION_TYPES.FETCH_EVENT_ATTENDEE_LIST):
            return {
                ...state,
                loading: false,
                updating: false,
                updateSuccess: false,
                errorMessage: action.payload,
            };
        case SUCCESS(ACTION_TYPES.FETCH_EVENT_ATTENDEE_LIST):
            return {
                ...state,
                loading: false,
                entities: action.payload.data,
            };
        case SUCCESS(ACTION_TYPES.FETCH_EVENT_STATS):
            return {
                ...state,
                loading: false,
                stats: action.payload.data,
            };
        case ACTION_TYPES.RESET:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};

const apiUrl = '/events';

// Actions
export const getEntities = (eventId) => ({
    type: ACTION_TYPES.FETCH_EVENT_ATTENDEE_LIST,
    payload: api.instance.get(`${apiUrl}/${eventId}/attendance?cacheBuster=${new Date().getTime()}`),
});


// Actions
export const getStats = (eventId) => ({
    type: ACTION_TYPES.FETCH_EVENT_STATS,
    payload: api.instance.get(`/admin/events/${eventId}/stats?cacheBuster=${new Date().getTime()}`),
});

export const reset = () => ({
    type: ACTION_TYPES.RESET,
});
