import { combineReducers } from 'redux';
import layout from "./layoutReducer";
import auth from "./authReducer";
import context from "./contextReducer";
import organisation from "./organisationReducer";
import environment from "./environmentReducer";
import webhook from "./webhookReducer";
import apikey from "./apiKeyReducer";
import event from "./eventReducer";
import eventAttendee from "./eventAttendeeReducer";
import user from "./userReducer"

export default combineReducers({
    layout,
    auth,
    context,
    organisation,
    environment,
    webhook,
    apikey,
    event,
    eventAttendee,
    user
});
